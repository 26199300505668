import React from 'react'

export default () => (
    <div>
        <h2 id="request-a-quote">Request a project quote</h2>
        <p>Have a game or mobile app that needs dev help? Then let's work together.</p>
        <p>
            Shoot me an email at&nbsp;&nbsp;
          <code
                style={{
                    backgroundColor: "#eee",
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                }}
            >
                jasontu4@gmail.com
          </code>
          &nbsp;&nbsp; with details about your project, and I'll get back to you
          within the next business day.
        </p>
    </div>
)
