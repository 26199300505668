import stAnnesTheme from "typography-theme-st-annes"
import { DiabeticRed } from "./colors"

stAnnesTheme.googleFonts = [
  {
    name: "Source Serif Pro",
    styles: ["600"],
  },
  {
    name: "Source Sans Pro",
    styles: ["300i", "400", "400i", "700", "700i"],
  },
]

stAnnesTheme.baseFontSize = "18px"

stAnnesTheme.overrideThemeStyles = ({ rhythm }, options) => ({
  a: {
    color: DiabeticRed,
  },
})

const BodyFontFamily = stAnnesTheme.bodyFontFamily
  .map((font, i) =>
    i !== stAnnesTheme.bodyFontFamily.length - 1 ? `'${font}'` : font
  )
  .join(", ")

export default stAnnesTheme
export { BodyFontFamily }
