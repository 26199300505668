import { DiscussionEmbed } from 'disqus-react'
import { rhythm } from "../utils/typography"
import React from 'react';

class StyledDiscussionEmbed extends DiscussionEmbed {
	render() {
		const { style } = this.props
		if (style) return <div id="disqus_thread" style={style} />
		return <div id="disqus_thread" />
	}
}

const Comments = props => {
  const { identifier, url, title } = props

  if (!identifier) {
    throw new Error("Missing Disqus identifier.")
  }

	if (!title) {
		throw new Error("Missing title.")
	}

  const disqusConfig = {
    shortname: 'teamavocado',
    config: {
      identifier,
      title,
		url,
    },
  }

	return (
    <StyledDiscussionEmbed
      style={{ marginTop: rhythm(1.5) }}
      {...disqusConfig}
    />
  )
}

export default Comments
