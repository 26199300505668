import React, { useState } from "react"
import { DiabeticRed } from "../utils/colors"
import { rhythm, scale } from "../utils/typography"
import { useMediaQuery } from "react-responsive"
import addToMailchimp from "gatsby-plugin-mailchimp"

const EmailSignup = props => {
  const altColor = props && props.altColor
  const [value, setValue] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [status, setStatus] = useState("idle") // 'idle' | 'loading' | 'success' | 'error'
  const urlPath = props.urlPath

  // Computed props.
  const isEnabled = status === "idle" || status === "error"

  const handleSubmit = async e => {
    // Always prevent default form event, since we're handling ourselves.
    e.preventDefault()

    // If user already submitted, return early.
    if (status !== "idle" && status !== 'error') {
      return
    }

    // Submit to Mailchimp.
    let res
    try {
      // Update values.
      setSuccessMessage("")
      setErrorMessage("")
      setStatus("loading")

      // Attempt submission.
      if (urlPath) {
        res = await addToMailchimp(value, {
          URLPATH: urlPath,
        })
      } else {
        res = await addToMailchimp(value)
      }
      console.log("Response:", res)
    } catch (err) {
      // Handle exceptions.
      console.error(err)
      setSuccessMessage("")
      setErrorMessage(`Couldn't submit email. Please try again? 🙏`)
      setStatus("error")
      return
    }

    // Handle failed validation.
    if (res && res.result === "error") {
      if (res.msg.indexOf("is already subscribed") > -1) {
        const [email] = res.msg.split(" ")
        setSuccessMessage(`${email} is already subscribed, you are all set!`)
        setErrorMessage("")
        setStatus("success")
      } else {
        setSuccessMessage("")
        setErrorMessage(res.msg)
        setStatus("error")
      }
      return
    }

    // Handle general error case.
    if (res && res.result !== "success") {
      // Handle exceptions.
      console.error(res)
      setSuccessMessage("")
      setErrorMessage(`Couldn't submit email. Please try again? 🙏`)
      setStatus("error")
      return
    }

    // Handle success case.
    setSuccessMessage(res.msg)
    setErrorMessage("")
    setStatus("success")
  }

  const { header, copy, style } = props
  if (!copy) throw new Error("No copy.")

  const isMobile = useMediaQuery({ maxWidth: 767 })
  const responsiveStyles = isMobile
    ? {
      paddingLeft: rhythm(1 / 2),
      paddingRight: rhythm(1 / 2),
      paddingTop: rhythm(1),
      paddingBottom: rhythm(1),
    }
    : {
      paddingLeft: rhythm(1),
      paddingRight: rhythm(1),
      paddingTop: rhythm(3 / 2),
      paddingBottom: rhythm(3 / 2),
    }

  return (
    <form
      className="EmailSignup"
      style={{
        backgroundColor: altColor ? 'transparent' : DiabeticRed,
        color: altColor ? 'currentcolor' : "white",
        ...responsiveStyles,
        ...style,
      }}
      onSubmit={handleSubmit}
    >
      {header &&
        <h2 style={{ color: altColor ? 'currentcolor' : "white", marginTop: 0 }}>{header}</h2>
      }
      <p style={{ marginBottom: rhythm(1 / 4) }}>{copy}</p>
      <input
        aria-label="email"
        type="email"
        placeholder="your@email.com"
        name="email"
        value={value}
        onChange={e => setValue(e.target.value)}
        style={{
          width: "100%",
          padding: rhythm(1 / 2),
          borderRadius: 5,
          border: altColor ? 'solid 1px black' : "none",

          // Tweak outline a little for clarity.
          ...(altColor ? {} : {
            outlineColor: "white",
            outlineOffset: 5,
          }),

          ...scale(1 / 4),
        }}
      />
      {status === "success" && (
        <div style={{ color: altColor ? 'black' : "white", marginTop: rhythm(1 / 4) }}>
          {successMessage}
        </div>
      )}
      {status === "error" && (
        <div style={{ color: altColor ? 'black' : "white", marginTop: rhythm(1 / 4) }}>
          {errorMessage}
        </div>
      )}
      <button
        type="submit"
        disabled={!isEnabled}
        style={{
          backgroundColor: isEnabled ? "#f44336" : "#ef5350",
          color: "white",
          border: "none",
          borderRadius: 5,
          textAlign: "center",
          paddingTop: rhythm(1 / 2),
          paddingBottom: rhythm(1 / 2),
          paddingLeft: rhythm(1),
          paddingRight: rhythm(1),
          width: "100%",
          marginTop: rhythm(1),
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...scale(1 / 4),
        }}
      >
        {status === "loading" && (
          <>
            <div className="LoadingIcon" />{" "}
            <div style={{ marginLeft: rhythm(1 / 4) }}>Submitting...</div>
          </>
        )}
        {status === "success" && "You are signed up! 🎉"}
        {(status === "idle" || status === "error") && "Sign me up!"}
      </button>
    </form>
  )
}

export { EmailSignup }
