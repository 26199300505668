import { useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import React from "react"
import { OpenInNewTab } from './OpenInNewTab'

export const ProdigiesBellsLink = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "prodigies_ipad1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <OpenInNewTab href="/prodigies-case-study/">
      <Image
        fluid={data.file.childImageSharp.fluid}
        alt="case study of Prodigies Bells, an iOS app I built for a client"
      />
    </OpenInNewTab>
  )
}
