import React from "react"
import { Testimonial } from "../Testimonial"
import { Aaron } from "../Aaron"

const AaronTestimonial = () => {
  return (
    <Testimonial
      description={
        <>
          Jason's one of those rare guys who exudes quiet brilliance — he has
          great practices and code quality, he finishes projects quickly and
          effectively, and he learns fast. He holds strong opinions about the
          right way to do things, and seems to relish the chance to dig into the
          subject matter and find the best solution to a given problem.<br /><br />
          <strong>
            Overall, Jason's the kind of guy you want on your team. Not only
            will he get the job done, he'll make the whole team better while he
            does it.
          </strong>
        </>
      }
      person={
        <a
          href="https://www.linkedin.com/in/jasontu/details/recommendations/"
          target="_blank"
          rel="noreferrer"
        >
          Aaron Buxbaum, Former Tech Lead at Jet.com (acquired by Walmart)
        </a>
      }
      photo={<Aaron />}
    />
  )
}

export { AaronTestimonial }
