import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { BodyFontFamily } from "../utils/theme"
import { rhythm } from "../utils/typography"
import { EggGame } from "./EggGame"

/*
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}
*/

const CarouselImage = ({
  fluidImage,
  title,
  projectUrl,
  whatIDid,
  genre,
  platform,
  customImage,
}) => {
  return (
    <div style={{ marginTop: rhythm(1), marginBottom: rhythm(1) }}>
      {customImage ? (
        customImage
      ) : (
        <a href={projectUrl} target="_blank" rel="noreferrer">
          <Img
            fluid={fluidImage}
            style={{ marginBottom: rhythm(1 / 2), maxHeight: 300 }}
            imgStyle={{ objectFit: "contain" }}
          />
        </a>
      )}
      <h4
        style={{
          fontFamily: BodyFontFamily,
          marginTop: 0,
          marginBottom: rhythm(1 / 4),
        }}
      >
        {title}
      </h4>
      <p style={{ marginBottom: rhythm(1 / 4), fontStyle: "italic" }}>
        {whatIDid}
      </p>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <small>
          <strong>Genre:</strong> {genre}
        </small>
        <br />
        <small>
          <strong>Platform:</strong> {platform}
        </small>
      </div>
    </div>
  )
}

const CarouselPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "pocket_pong_ar.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "prodigies_ipad1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "spots.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "metroidvania.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(relativePath: { eq: "concepts.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <CarouselImage
        fluidImage={data.image1.childImageSharp.fluid}
        title="Pocket Pong AR"
        projectUrl="/finishing-games"
        whatIDid={
          <>
            I designed, scoped, implemented, released, and marketed{" "}
            <a
              href="https://nucleartide.itch.io/pocket-pong-ar"
              target="_blank"
              rel="noreferrer"
            >
              Pocket Pong AR
            </a>{" "}
            — a mobile AR (Augmented Reality) game for iOS.{" "}
            <a href="/finishing-games" target="_blank" rel="noreferrer">
              See the corresponding writeup
            </a>
            .
          </>
        }
        genre="Action, Arcade"
        platform="iOS"
      />
      <CarouselImage
        fluidImage={data.image2.childImageSharp.fluid}
        title="Prodigies Bells"
        projectUrl="/prodigies-case-study"
        whatIDid={
          <>
            Give your kids the gift of an early music education with Prodigies
            Bells, an educational music app available on iOS and Android. As a
            sole freelancer, I worked with Prodigies Music to rehaul their iOS
            and Android apps (built in Unity), and boost their app ratings.{" "}
            <a href="/prodigies-case-study" target="_blank">
              See the corresponding writeup
            </a>
            .
          </>
        }
        genre="Education, Music"
        platform="iOS, Android"
      />
      <CarouselImage
        fluidImage={data.image3.childImageSharp.fluid}
        title="Spots"
        projectUrl="https://github.com/JasonTuAtWonder/Spots"
        whatIDid={
          <>
            A minimalist, match-3 game for web browsers. How many square matches
            can you find? This game is completely open source, and demonstrates
            many coding principles in Unity.{" "}
            <a href="https://github.com/JasonTuAtWonder/Spots" target="_blank" rel="noreferrer">
              View the repo here
            </a>
            .
          </>
        }
        genre="Puzzle"
        platform="Web"
      />
      <CarouselImage
        title="Untitled Egg Game"
        projectUrl="https://twitter.com/nucleartide/status/1278360929902739456"
        whatIDid={
          <>
            Making pixelated breakfast... in PICO-8. A personal, prototype
            project.{" "}
            <a
              href="https://twitter.com/nucleartide/status/1278360929902739456"
              target="_blank"
              rel="noreferrer"
            >
              See the tweet
            </a>
            .
          </>
        }
        genre="Simulation"
        platform="Web, Windows, Mac, Linux (PICO-8)"
        customImage={
          <a
            href="https://twitter.com/nucleartide/status/1278360929902739456"
            target="_blank"
            rel="noreferrer"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: rhythm(1 / 2),
            }}
          >
            <EggGame />
          </a>
        }
      />
      <CarouselImage
        fluidImage={data.image4.childImageSharp.fluid}
        title="How to design a metroidvania"
        projectUrl="https://www.youtube.com/watch?v=GWioZ8ahge0"
        whatIDid={
          <>
            I created a YouTube video analyzing the game design of a
            metroidvania. It remains my most popular video at 14k+ views.{" "}
            <a
              href="https://www.youtube.com/watch?v=GWioZ8ahge0"
              target="_blank"
              rel="noreferrer"
            >
              See the video here
            </a>
            .
          </>
        }
        genre="Game Maker's Toolkit—like Analysis"
        platform="YouTube"
      />
      <CarouselImage
        fluidImage={data.image5.childImageSharp.fluid}
        title="How to make an Augmented Reality game in Unity"
        projectUrl="https://www.youtube.com/playlist?list=PLopdNsnVmfA57K211UHyKn7Wjb5AYfEJY"
        whatIDid={
          <>
            I created a YouTube series that steps through how to make an
            Augmented Reality game in Unity using AR Foundation.{" "}
            <a
              href="https://www.youtube.com/playlist?list=PLopdNsnVmfA57K211UHyKn7Wjb5AYfEJY"
              target="_blank"
              rel="noreferrer"
            >
              See the playlist here
            </a>
            .
          </>
        }
        genre="YouTube Tutorial"
        platform="YouTube"
      />
    </>
  )
}

export { CarouselPage }
