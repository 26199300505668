import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Claire = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "claire.jpeg" }) {
        childImageSharp {
          fixed(width: 80, height: 80) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      style={{ borderRadius: "50%" }}
    />
  )
}

export { Claire }
