import React from "react"
import { Testimonial } from "../Testimonial"
import { Claire } from "../Claire"

const ClaireTestimonial = () => {
  return (
    <Testimonial
      description={
        <>
          I worked together with Jason at Wonder for a year. Jason is amazing at
          front-end development and has a great vision for his work. He focuses
          on his tasks not only to meet deadlines but also to high standards.
          <br />
          <br />
          Above all, he is very open and helpful. He has never hesitated to
          provide support to new joiners and new people to work with.
        </>
      }
      person={
        <a
          rel="noreferrer"
          href="https://www.linkedin.com/in/jasontu/details/recommendations/"
          target="_blank"
        >
          Claire Park, Software Engineer at Wonder
        </a>
      }
      photo={<Claire />}
    />
  )
}

export { ClaireTestimonial }
