import React from "react"
import { Testimonial } from "../Testimonial"
import { Jones } from "../Jones"

const JonesTestimonial = () => {
  return (
    <Testimonial
      description={
        <>
          Jason and I worked together as software developers on the IMS team
          (Inventory Management System) at Wonder. As a front-end expert, Jason
          proactively provided me mentorship and a series of training, which
          helped me a lot in improving my front-end skills and knowledge.
          <br />
          <br />
          Jason is a very proactive individual who often comes up with
          innovative ideas and loves to try different solutions. He is willing
          to share knowledge and often hosts speeches, trying to pass on more
          interesting self-improvement experiences to other colleagues.
          <br />
          <br />
          He is passionate about his work and is also very good at using
          document writing to record and pass on various knowledge.
          <br />
          <br />
          Highly recommend Jason to any team looking for experienced and strong
          engineers.
        </>
      }
      person={
        <a
          href="https://www.linkedin.com/in/jasontu/details/recommendations/"
          target="_blank"
          rel="noreferrer"
        >
          Chung-Hui Wang, Software Engineer at Wonder
        </a>
      }
      photo={<Jones />}
    />
  )
}

export { JonesTestimonial }
