import React from "react"
import { GRAY_BACKGROUND } from "../../utils/colors"
import { BodyFontFamily } from "../../utils/theme"
import { rhythm, scale } from "../../utils/typography"

const CTAFirst = ({ doNotViewBelow }) => {
  return (
    <div
      style={{
        backgroundColor: GRAY_BACKGROUND,
      }}
    >
      <section
        style={{
          margin: "0 auto",
          paddingRight: rhythm(1 / 2),
          paddingLeft: rhythm(1 / 2),
          paddingTop: rhythm(2),
          paddingBottom: rhythm(2),
          maxWidth: 600,
        }}
        className="FreelanceEmailSignup"
      >
        <h2
          style={{
            fontFamily: BodyFontFamily,
            marginTop: 0,
            textAlign: "center",
          }}
        >
          Looking for a senior Unity developer?
        </h2>
        <a
          href="https://forms.gle/p5quCkVcqKZSFcws6"
          target="_blank"
          rel="noreferrer"
          style={{
            backgroundColor: "#f44336",
            color: "white",
            borderRadius: 5,
            border: "none",
            textAlign: "center",
            width: "100%",
            display: "block",
            paddingTop: rhythm(1 / 2),
            paddingBottom: rhythm(1 / 2),
            paddingLeft: rhythm(1),
            paddingRight: rhythm(1),
            cursor: "pointer",
            ...scale(1 / 4),
          }}
        >
          Talk with me ↗
        </a>
        {!doNotViewBelow && (
          <h2
            style={{
              fontWeight: 100,
              fontFamily: BodyFontFamily,
              textAlign: "center",
              fontStyle: "italic",
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            Or view my portfolio below ↓
          </h2>
        )}
      </section>
    </div>
  )
}

export { CTAFirst }
