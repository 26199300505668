import { OpenInNewTab } from "../../../../src/components/OpenInNewTab";
import { CarouselPage } from "../../../../src/components/TestCarousel";
import { CTAFirst } from "../../../../src/components/freelance/CTA";
import { AaronTestimonial } from "../../../../src/components/freelance/AaronTestimonial";
import { ClaireTestimonial } from "../../../../src/components/freelance/ClaireTestimonial";
import { JonesTestimonial } from "../../../../src/components/freelance/JonesTestimonial";
import { JudyTestimonial } from "../../../../src/components/freelance/JudyTestimonial";
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import { PocketPongLink } from "../../../../src/components/PocketPongLink";
import { SpotsLink } from "../../../../src/components/SpotsLink";
import { ProdigiesBellsLink } from "../../../../src/components/ProdigiesBellsLink";
import { EggGame } from "../../../../src/components/EggGame";
import * as React from 'react';
export default {
  OpenInNewTab,
  CarouselPage,
  CTAFirst,
  AaronTestimonial,
  ClaireTestimonial,
  JonesTestimonial,
  JudyTestimonial,
  Image,
  Link,
  PocketPongLink,
  SpotsLink,
  ProdigiesBellsLink,
  EggGame,
  React
};