import React from "react"
import { Testimonial } from "../Testimonial"

const JudyTestimonial = () => {
  return (
    <Testimonial
      description={
        <>
          In the last five months that I worked with Jason on Wonder's Inventory
          Management System team, he set aside time to give me weekly mentoring
          for frontend and career development even though he was not my direct
          manager. The weekly exercises that came out of these meetings helped
          me get a better grasp of frontend development and pointed me in a
          better direction going forward.
          <br />
          <br />
          Aside from the weekly mentoring, Jason was also very meticulous and
          organized with his work and provided thoughtful and helpful feedback
          for the team. He took extra steps to improve our project and made sure
          that work of high is shipped for our backend, web app and mobile app.
          He held these same high standards for his teammates as well which I
          learned a lot from.
          <br />
          <br />
          Any future team would be lucky to have Jason if they are looking for
          high quality work to be done.
        </>
      }
      person={
        <a
          href="https://www.linkedin.com/in/jasontu/details/recommendations/"
          target="_blank"
          rel="noreferrer"
        >
          Judy Liu, Software Engineer at Wonder
        </a>
      }
    />
  )
}

export { JudyTestimonial }
