import Image from "gatsby-image"
import React from "react"
import { Link, useStaticQuery } from "gatsby"

export const PocketPongLink = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "pocket_pong_ar.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Link href="https://nucleartide.itch.io/pocket-pong-ar" target="_blank">
      <Image
        fluid={data.file.childImageSharp.fluid}
        alt="Pocket Pong AR: it's Pong... but with a twist!"
      />
    </Link>
  )
}
