import React from "react"
import { useMediaQuery } from "react-responsive"
import { Gray } from "../utils/colors"
import { rhythm } from "../utils/typography"

export function Testimonial(props) {
  const description = props.description || "this is a description"
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const person = props.person
  const photo = props.photo

  return (
    <div
      style={{
        paddingTop: 40,
      }}
    >
      <div
        className="Testimonial"
        style={{
          padding: 0,
          display: "block",
          borderWidth: 1,
          borderStyle: "solid",
        }}
      >
        <div
          style={{
            paddingLeft: isMobile ? rhythm(1 / 2) : rhythm(1),
            paddingRight: isMobile ? rhythm(1 / 2) : rhythm(1),
            paddingTop: rhythm(2),
            paddingBottom: rhythm(1),
            borderTopColor: "#fcea0e",
            borderTopWidth: rhythm(3 / 8),
            borderTopStyle: "solid",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {photo && (
            <div
              style={{
                position: "absolute",
                top: rhythm(-3 / 8),
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: -40,
                  left: -40,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {photo}
              </div>
            </div>
          )}
          <p
            style={{
              color: "black",
              marginTop: 0,
              marginBottom: rhythm(1),
              fontStyle: "italic",
            }}
          >
            “{description}”
          </p>
          <div
            className="CallToAction"
            style={{ color: Gray, alignSelf: "flex-end" }}
          >
            <em>{person}</em>
          </div>
        </div>
      </div>
    </div>
  )
}
