import React from "react"

export const EggGame = () => {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video controls height="300">
      <source src="/egg_game.mp4" type="video/mp4" />
    </video>
  )
}
