import Image from "gatsby-image"
import React from "react"
import { Link, useStaticQuery } from "gatsby"

export const SpotsLink = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "spots.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Link href="https://spots-nucleartide.vercel.app/" target="_blank">
      <div className="games__spots-container">
        <Image
          fluid={data.file.childImageSharp.fluid}
          alt="Spots, a minimalist, dot-matching game"
        />
      </div>
    </Link>
  )
}
