// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-js": () => import("./../../../src/pages/all.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-augmented-reality-js": () => import("./../../../src/pages/augmented-reality.js" /* webpackChunkName: "component---src-pages-augmented-reality-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-etudes-for-the-web-browser-js": () => import("./../../../src/pages/etudes-for-the-web-browser.js" /* webpackChunkName: "component---src-pages-etudes-for-the-web-browser-js" */),
  "component---src-pages-first-10-games-js": () => import("./../../../src/pages/first10games.js" /* webpackChunkName: "component---src-pages-first-10-games-js" */),
  "component---src-pages-game-codebase-tours-js": () => import("./../../../src/pages/game-codebase-tours.js" /* webpackChunkName: "component---src-pages-game-codebase-tours-js" */),
  "component---src-pages-game-codebases-explained-js": () => import("./../../../src/pages/game-codebases-explained.js" /* webpackChunkName: "component---src-pages-game-codebases-explained-js" */),
  "component---src-pages-game-design-js": () => import("./../../../src/pages/game-design.js" /* webpackChunkName: "component---src-pages-game-design-js" */),
  "component---src-pages-game-juice-js": () => import("./../../../src/pages/game-juice.js" /* webpackChunkName: "component---src-pages-game-juice-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-pico-8-js": () => import("./../../../src/pages/pico8.js" /* webpackChunkName: "component---src-pages-pico-8-js" */),
  "component---src-pages-pocket-pong-ar-privacy-js": () => import("./../../../src/pages/pocket-pong-ar-privacy.js" /* webpackChunkName: "component---src-pages-pocket-pong-ar-privacy-js" */),
  "component---src-pages-productivity-js": () => import("./../../../src/pages/productivity.js" /* webpackChunkName: "component---src-pages-productivity-js" */),
  "component---src-pages-professional-game-programmer-js": () => import("./../../../src/pages/professional-game-programmer.js" /* webpackChunkName: "component---src-pages-professional-game-programmer-js" */),
  "component---src-pages-shaders-js": () => import("./../../../src/pages/shaders.js" /* webpackChunkName: "component---src-pages-shaders-js" */),
  "component---src-pages-unity-3-d-js": () => import("./../../../src/pages/unity3d.js" /* webpackChunkName: "component---src-pages-unity-3-d-js" */),
  "component---src-pages-your-first-ten-games-js": () => import("./../../../src/pages/your-first-ten-games.js" /* webpackChunkName: "component---src-pages-your-first-ten-games-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

